<template>
  <div class="profile-username">{{ email }}</div>
</template>

<script>
import authentication from "../../authentication";

export default {
  computed: {
    email() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserEmail();
    },
  },
};
</script>
